<template>
  <transition name="van-slide-right">
    <div v-show="visible">
      <NavBar/>
      <van-form @submit="onSubmit">
        <div class="head">
          <van-row gutter="20">
            <van-col span="12">
              <van-field name="paymentType" label-width="0">
                <template #input>
                  <van-radio-group v-model="keepAccounts.paymentType" direction="horizontal"
                                   @change="paymentTypeChange">
                    <van-radio name="1" shape="square">支出</van-radio>
                    <van-radio name="0" shape="square">收入</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
            </van-col>
            <van-col span="12" style="padding-right: 10px">
              <van-field
                  readonly
                  clickable
                  name="keepTimeDay"
                  :value="title"
                  placeholder="点击选择时间"
                  @click="dateSelectShow = true"
              />
              <van-calendar v-model="dateSelectShow" @confirm="dateSelect"/>
            </van-col>
          </van-row>
          <van-row gutter="20">
            <van-col span="24" style="padding: 10px">
              <van-field v-model="keepAccounts.money" label="￥:" placeholder="0.00" label-width="2em"
                         class="amount-class" type="number" name="money"
                         :rules="[
                           { required: true, message: '请输入金额' },
                           { pattern, message: '请输入正确内容(小数点后两位)' }]"/>
            </van-col>
          </van-row>
          <div>
            <van-divider content-position="left">{{ paymentTypeName }}</van-divider>
            <div style="margin: 10px;text-align: center">
              <van-field name="feeTypeId" label-width="0"
                         :rules="[
                           { required: true, message: '请选择类型' }]">
                <template #input>
                  <van-radio-group v-model="keepAccounts.feeTypeId" direction="horizontal">
                    <van-radio :name="item.id" v-for="item in keepFeeTypes" :key="item.id" shape="square"
                               style="margin: 10px">{{ item.feeTypeName }}
                    </van-radio>
                  </van-radio-group>
                </template>
              </van-field>
            </div>
          </div>
          <van-row gutter="20">
            <van-col span="24" style="padding: 10px">
              <van-field
                  readonly
                  clickable
                  label="业务合同"
                  name="contractName"
                  :value="keepAccounts.contractName"
                  placeholder="请选择业务合同"
                  @click="showPicker = true"/>
              <van-popup v-model="showPicker" round position="bottom">
                <van-picker
                    show-toolbar
                    :columns="contracts"
                    @cancel="showPicker = false"
                    @confirm="onConfirm"
                />
              </van-popup>
            </van-col>
          </van-row>
        </div>
        <van-divider content-position="left">费用单据（选填）</van-divider>
        <div class="upload-class">
          <van-field name="feeArrays">
            <template #input>
              <van-uploader v-model="feeArrays" multiple :max-count="9" accept="image/*" :afterRead="uploadFile"
                            :before-delete="delUploadFile" name="feeArrays"/>
            </template>
          </van-field>
        </div>
        <van-divider content-position="left">发票（选填）</van-divider>
        <div class="upload-class">
          <van-field name="invoiceArrays">
            <template #input>
              <van-uploader v-model="invoiceArrays" multiple :max-count="9" accept="image/*" :afterRead="uploadFile"
                            :before-delete="delUploadFile" name="invoiceArrays"/>
            </template>
          </van-field>
        </div>
        <van-divider content-position="left">业务佐证（选填）</van-divider>
        <div class="upload-class">
          <van-field name="epiArrays">
            <template #input>
              <van-uploader v-model="epiArrays" multiple :max-count="9" accept="image/*" :afterRead="uploadFile"
                            :before-delete="delUploadFile" name="epiArrays"/>
            </template>
          </van-field>
        </div>
        <div style="padding:10px 10px 20px 10px;text-align: center">
          <van-button type="info" block round native-type="submit">提交</van-button>
        </div>
      </van-form>


      <!--    <van-calendar v-model="dateSelectShow" :show-confirm="false" @confirm="dateSelect" :min-date="new Date(2020,1,1)"-->
      <!--                  :max-date="new Date(2030,1,1)"/>-->
    </div>
  </transition>
</template>

<script>
import NavBar from "@/components/NavBar";
import cos from "@/utils/objectQCos"
import {getKeepContractList, getKeepFeeTypeList, getTempSecretKey} from "@/api/comm/comm";
import {getDateNumber} from "@/utils/dateUtils";
import {getUUID} from "@/utils/UUIDUtils";
import VantMessage from "@/utils/vantMessageUtils";
import {addKeepAccounts, delKeepAccounts, delKeepAccountsFile, getKeepAccounts, updKeepAccounts} from "@/api/cost/cost";

export default {
  name: "updateCost",
  data() {
    return {
      visible: false,
      pattern: /^([0-9]|[1-9]\d+)(\.\d{2})?$/,
      feeArrays: [],
      invoiceArrays: [],
      epiArrays: [],
      feeArray: [],
      invoiceArray: [],
      epiArray: [],
      paymentTypeName: '支出类型',
      fileSize: true,
      costReceiptsShow: true,
      keepFeeTypes: [],
      contracts: [],
      keepAccounts: {},
      amount: '0',
      numberKeyboardShow: false,
      dateSelectShow: false,
      direction: 'down',
      title: '',
      showPicker: false,
    };
  },
  components: {
    NavBar,
  },
  methods: {
    async getKeepAccounts(id) {
      let res = await getKeepAccounts({keepAccountsId: id})
      if (res) {
        this.keepAccounts = res.keepAccounts
        this.keepAccounts.paymentType = this.keepAccounts.paymentType.toString()
        for (let i = 0; i < res.epiList.length; i++) {
          this.epiArrays.push({
            url: res.epiList[i].fileUrl,
            id: res.epiList[i].id,
            beforeDelete: (file, detail) => {
              VantMessage.confirmMsg("删除该图片会永久删除，你确定要删除吗").then(async resolve => {
                if (resolve) {
                  await this.delUploadFile(file, detail, 1)
                }
              })
            },
          })
          this.epiArray.push(res.epiList[i].fileUrl)
        }
        for (let i = 0; i < res.feeList.length; i++) {
          this.feeArrays.push({
            url: res.feeList[i].fileUrl,
            id: res.feeList[i].id,
            beforeDelete: (file, detail) => {
              VantMessage.confirmMsg("删除该图片会永久删除，你确定要删除吗").then(async resolve => {
                if (resolve) {
                  await this.delUploadFile(file, detail, 1)
                }
              })
            },
          })
          this.feeArray.push(res.feeList[i].fileUrl)
          // this.feeArray.push({
          //   url: res.feeList[i].fileUrl,
          //   id: res.feeList[i].id
          // })
        }
        for (let i = 0; i < res.invoiceList.length; i++) {
          this.invoiceArrays.push({
            url: res.invoiceList[i].fileUrl,
            id: res.invoiceList[i].id,
            beforeDelete: (file, detail) => {
              VantMessage.confirmMsg("删除该图片会永久删除，你确定要删除吗").then(async resolve => {
                if (resolve) {
                  await this.delUploadFile(file, detail, 1)
                }
              })
            },
          })
          this.invoiceArray.push(res.invoiceList[i].fileUrl)
        }

        this.title = this.keepAccounts.keepTimeDay
      }
    },
    paymentTypeChange(name) {
      this.paymentTypeName = name === "0" ? "支出类型" : "收入类型";
    },
    async onSubmit(values) {
      const loading = VantMessage.loadingMsg("提交中...")
      values.contractId = this.keepAccounts.contractId
      delete values.feeArrays;
      delete values.invoiceArrays;
      delete values.epiArrays;
      values.feeArray = this.feeArray;
      values.invoiceArray = this.invoiceArray;
      values.epiArray = this.epiArray;
      values.id = this.keepAccounts.id
      let this_ = this;
      let res = await updKeepAccounts(values)
      loading.clear()
      if (res) {
        VantMessage.successMsg("修改成功")
        setTimeout(() => {
          this_.$router.push({
            path:'/cost',
            query:{keepAccountsId:this_.keepAccounts.id}
        })
        }, 1000);
      }

    },
    onConfirm(value) {
      this.keepAccounts.contractId = value.id;
      this.keepAccounts.contractName = value.text;
      this.showPicker = false;
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    dateSelect(value) {
      this.keepAccounts.keepTimeDay = this.formatDate(value);
      this.title = this.keepAccounts.keepTimeDay
      this.dateSelectShow = false;
    },
    //判断图片
    isOverSize(file) {
      if (file.size > 5242880) {
        this.$toast("请上传小于5M的图片")
        this.fileSize = false;
        return this.fileSize;
      }
      this.fileSize = true;
    },
    //上传图片
    async uploadFile(file, detail) {
      file.status = 'uploading';
      file.message = '上传中...';
      let fileName = file.file.name;
      let type = fileName.substring(fileName.lastIndexOf("."));//.tx
      file.name = getDateNumber() + getUUID() + type;
      let res = await getTempSecretKey(null);
      let this_ = this;
      cos.putObject(res, file, (error, data) => {
        if (error) {
          file.status = 'failed';
          file.message = '上传失败';
        } else {
          file.status = 'done ';
          file.message = '上传成功';
          switch (detail.name) {
            case 'feeArrays':
              this_.feeArrays[detail.index].status = 'done';
              this_.feeArray.push("https://" + data.Location)
              break;
            case 'invoiceArrays':
              this_.invoiceArrays[detail.index].status = 'done';
              this_.invoiceArray.push("https://" + data.Location)
              break;
            case 'epiArrays':
              this_.epiArrays[detail.index].status = 'done';
              this_.epiArray.push("https://" + data.Location);
              // this_.epiArray[detail.index].imgUrl = "https://" + data.Location;
              break;
            default:
              this.$toast("系统异常，请联系客服")
              break;
          }
        }
      })
    },
    //删除图片
    async delUploadFile(file, detail, type) {
      const arr = []
      let fileImg = ''
      // let this_ = this;
      if (type) {
        fileImg = file.url;
      } else {
        switch (detail.name) {
          case 'feeArrays':
            fileImg = this.feeArray[detail.index]
            break;
          case 'invoiceArrays':
            fileImg = this.invoiceArray[detail.index]
            break;
          case 'epiArrays':
            fileImg = this.epiArray[detail.index]
            break;
          default:
            break;
        }
      }
      fileImg.split('/').forEach((item, index) => {
        if (index !== 0 && index !== 1 && index !== 2) {
          arr.push(item)
        }
      })
      fileImg = arr.join('/');
      let res = await getTempSecretKey(null);
      if (res) {
        await cos.delObject(res, fileImg, (error, data) => {
          if (error) {
            console.log(error)
          } else {
            if (type) {
              this.delKeepAccountsFile(file.id);
            }
            switch (detail.name) {
              case 'feeArrays':
                if (!type) {
                  this.feeArray.splice(detail.index, 1)
                }
                this.feeArrays.splice(detail.index, 1)
                break;
              case 'invoiceArrays':
                if (!type) {
                  this.invoiceArray.splice(detail.index, 1)
                }
                this.invoiceArrays.splice(detail.index, 1)
                break;
              case 'epiArrays':
                if (!type) {
                  this.epiArray.splice(detail.index, 1)
                }
                this.epiArrays.splice(detail.index, 1)
                break;
              default:
                break;
            }
          }
        })
      }
    },
    async delKeepAccountsFile(id) {
      let res = await delKeepAccountsFile({
        keepAccountsFileId: id
      })
      if (res) {
        VantMessage.simpleness("文件已删除")
      } else {
        VantMessage.errorMsg("删除错误")
      }
    },
    //查询收支出类型标签
    async getKeepFeeTypeList() {
      let res = await getKeepFeeTypeList(null);
      if (res.length > 0) {
        this.keepFeeTypes = res;
      }
    },
    //查询合同
    async getKeepContractList() {
      let res = await getKeepContractList(null);
      if (res.length > 0) {
        for (let i = 0; i < res.length; i++) {
          this.contracts.push({
            text: res[i].contractName,
            id: res[i].id
          })
        }
      }
    }
  },
  mounted() {
    this.visible = true;
    this.getKeepFeeTypeList();
    this.getKeepContractList();
    this.getKeepAccounts(this.$route.query.keepAccountsId);
    document.title = this.$route.meta.title
  }
}
</script>

<style scoped>
.head {
  /*margin-top: 10px;*/
  padding: 10px 0;
  /*text-align: center;*/
  width: 100vw;
  /*height: 250px;*/
  border-radius: 0 0 20px 20px;
  background-color: #FFF;
  /*box-shadow: 0px 15px 10px -15px;*/
}

.data-class {
  width: 200px;
  float: right;
  line-height: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  /*margin-top: 30px;*/
  /*background-color: #cd372e;*/
  font-size: 18px;
  /*color: white;*/
  border-radius: 10px
}

.upload-class {
  border-radius: 10px;
  background-color: #FFFFFF;
}

.tag-class {
  margin: 5px;
}

.amount-class {
  font-size: 25px;
  background-color: #f2f2f2;
  line-height: 25px;
  border-radius: 5px;
}
</style>
